import Vue from "vue";
import Router from "vue-router";
// import store from "./store";
// import axios from "axios";

Vue.use(Router);
let router = new Router({
  mode: "history",
  routes: [
    {
      path: "/",
      name:'mainHome',
      props: true,
      component: () => import("./components/MainHome/home"),
      meta: {
        requiresAuth: false,
      },
    },

    // {
    //     path: "/home",
    //     props: true,
    //     component: () =>
    //         import ("./components/Home/home"),
    //     meta: {
    //         requiresAuth: false,
    //     },
    // },

    {
      path: "/rubycon",
      props: true,
      name:'rubycon',
      component: () => import("./components/Common/rubycon"),
      meta: {
        requiresAuth: false,
      },
      children: [
        {
          path: "/home",
          name: "home",
          meta: {
            requiresAuth: true,
          },
          component: () => import("./components/Home/home"),
          props: true,
        },
        {
          path: "/allcourses",
          props: true,
          component: () => import("./components/Home/allcourses"),
          meta: {
            requiresAuth: false,
          },
        },
        {
          path: "/zencourse",
          props: true,
          component: () => import("./components/Home/zencourse"),
          meta: {
            requiresAuth: false,
          },
        },
    
        {
          path: "/onlinecourse",
          props: true,
          component: () => import("./components/Home/onlinecourse"),
          meta: {
            requiresAuth: false,
          },
        },
    
        {
          path: "/healthandsafetypage",
          props: true,
          component: () => import("./components/Home/healthandsafetypage"),
          meta: {
            requiresAuth: false,
          },
        },
        {
          path: "/healthandsafetydetails",
          props: true,
          component: () => import("./components/Home/healthandsafetydetails"),
          meta: {
            requiresAuth: false,
          },
        },
        {
          path: "/manualhandlingpage",
          props: true,
          component: () => import("./components/Home/manualhandlingpage"),
          meta: {
            requiresAuth: false,
          },
        },
        {
          path: "/manualhandlingdetails",
          props: true,
          component: () => import("./components/Home/manualhandlingdetails"),
          meta: {
            requiresAuth: false,
          },
        },
        {
          path: "/ppepage",
          props: true,
          component: () => import("./components/Home/ppepage"),
          meta: {
            requiresAuth: false,
          },
        },
        {
          path: "/ppedetails",
          props: true,
          component: () => import("./components/Home/ppedetails"),
          meta: {
            requiresAuth: false,
          },
        },
        {
          path: "/carmelcourse",
          props: true,
          component: () => import("./components/Home/carmelcourse"),
          meta: {
            requiresAuth: false,
          },
        },
        {
          path: "/courseotherview",
          props: true,
          component: () => import("./components/Home/courseotherview"),
          meta: {
            requiresAuth: false,
          },
        },
        {
          path: "/coursesingleview",
          props: true,
          component: () => import("./components/Home/coursesingleview"),
          meta: {
            requiresAuth: false,
          },
        },
        {
          path: "/othercoursesingleview",
          props: true,
          component: () => import("./components/Home/othercoursesingleview"),
          meta: {
            requiresAuth: false,
          },
        },
        {
          path: "/coursegerdetails",
          props: true,
          component: () => import("./components/Home/coursegerdetails"),
          meta: {
            requiresAuth: false,
          },
        },
    
        {
          path: "/officeautopage",
          props: true,
          component: () => import("./components/Home/officeautopage"),
          meta: {
            requiresAuth: false,
          },
        },
    
        {
          path: "/officeautodetails",
          props: true,
          component: () => import("./components/Home/officeautodetails"),
          meta: {
            requiresAuth: false,
          },
        },
    
        {
          path: "/tallypage",
          props: true,
          component: () => import("./components/Home/tallypage"),
          meta: {
            requiresAuth: false,
          },
        },
    
        {
          path: "/tallydetails",
          props: true,
          component: () => import("./components/Home/tallydetails"),
          meta: {
            requiresAuth: false,
          },
        },
        {
          path: "/multymediapage",
          props: true,
          component: () => import("./components/Home/multymediapage"),
          meta: {
            requiresAuth: false,
          },
        },
        {
          path: "/multymediadetails",
          props: true,
          component: () => import("./components/Home/multymediadetails"),
          meta: {
            requiresAuth: false,
          },
        },
        {
          path: "/zenintro",
          props: true,
          component: () => import("./components/Home/zenintro"),
          meta: {
            requiresAuth: false,
          },
        },
        {
          path: "/ladderpage",
          props: true,
          component: () => import("./components/Home/ladderpage"),
          meta: {
            requiresAuth: false,
          },
        },
        {
          path: "/ladderdetails",
          props: true,
          component: () => import("./components/Home/ladderdetails"),
          meta: {
            requiresAuth: false,
          },
        },
        {
          path: "/heightdetails",
          props: true,
          component: () => import("./components/Home/heightdetails"),
          meta: {
            requiresAuth: false,
          },
        },
        {
          path: "/heightpage",
          props: true,
          component: () => import("./components/Home/heightpage"),
          meta: {
            requiresAuth: false,
          },
        },
    
      ],
    },
    {
      path: "/aboutUs",
      props: true,
      component: () => import("./components/MainHome/aboutUs"),
      meta: {
        requiresAuth: false,
      },
    },

    {
      path: "/servicesHome",
      props: true,
      component: () => import("./components/MainHome/servicesHome"),
      meta: {
        requiresAuth: false,
      },
    },

    {
      path: "/contactUs",
      name:'realestate',
      props: true,
      component: () => import("./components/Common/contactUs"),
      meta: {
        requiresAuth: false,
      },
    },

   
    {
      path: "/privacyPolicy",
      props: true,
      component: () => import("./components/Common/privacyPolicy"),
      meta: {
        requiresAuth: false,
      },
    },
    {
      path: "/realestate",
      name:"realestate",
      props: true,
      component: () => import("./components/RealEstate/finalSection"),
      meta: {
        requiresAuth: false,
      },
    },
    {
      path: "/ServerError",
      name: "ServerError",
      props: true,
      component: () => import("./components/Common/500"),
      meta: {
        requiresAuth: false,
      },
    },
    {
      path: "*",
      name: "404PageNotFound",
      props: true,
      component: () => import("./components/Common/404"),
      meta: {
        requiresAuth: false,
      },
    },
  ],
  scrollBehavior() {
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0;
  },
});

export default router;
