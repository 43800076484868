<template>
  <div class="app">
    <!-- <v-app-bar color="#5d54a4"> -->

    <v-app-bar color="#3D3D3D" class="px-2" dense>
      <v-layout align-center justify-space-between wrap>
        <v-flex xs="auto" class="d-flex align-center">
          <v-icon color="white" size="15">mdi-phone</v-icon>
          <span class="headerfont ml-2" style="font-size: 12px; color: white">+91 9497326172</span>
          <v-icon v-if="$vuetify.breakpoint.smAndUp" color="white" size="15" class="ml-2">mdi-email</v-icon>
          <span v-if="$vuetify.breakpoint.smAndUp" class="headerfont ml-2"
            style="font-size: 12px; color: white">kumarakomventures@gmail.com</span>
        </v-flex>
        <v-flex xs="auto" class="d-flex align-center justify-end">
        <v-icon color="white" size="15" class="ml-2 mr-sm-3">mdi-instagram</v-icon>
        <v-icon color="white" size="15" class="ml-2 mr-sm-3">mdi-facebook</v-icon>
        <v-icon color="white" size="15" class="ml-2 mr-sm-3">mdi-twitter</v-icon>
        </v-flex>
        <v-flex xs12 sm-auto class="d-flex justify-start">
          <v-icon v-if="!$vuetify.breakpoint.smAndUp" color="white" size="15">mdi-email</v-icon>
        <span v-if="!$vuetify.breakpoint.smAndUp" class="headerfont ml-2"
            style="font-size: 12px; color: white">kumarakomventures@gmail.com</span>
        </v-flex>
      </v-layout>
    </v-app-bar>
    <v-layout wrap class="hidden-sm-and-down app px-0">
      <v-flex xs12 sm12 md12>
        <v-app-bar color="white" class="px-0" flat>
          <!-- <header> -->
          <v-layout wrap justify-center class="hidden-sm-and-down app px-0">
            <v-flex xs12 pt-lg-5 pt-md-5>
              <!-- <v-img src="../../assets/Images/hd.jpg"> -->
              <v-layout wrap fill-height>
                <v-flex xs12 md11 style="text-align: justify; cursor: pointer" text-center align-self-center>
                  <!-- <v-card color="white" elevation="0"> -->
                  <v-layout wrap>
                    <v-flex py-4 md3>
                      <router-link to="/" style="text-decoration: none">
                        <v-layout wrap>
                          <v-flex xs7>
                            <v-img contain style="cursor: pointer" text src="./../../assets/kumarakom.png"
                              height="60px"></v-img>
                          </v-flex>
                        </v-layout>
                      </router-link>
                    </v-flex>
                    <v-layout wrap justify-space-between class="headerfont">
                      <!-- <v-flex xs1 pt-1><a class="interSB" style="text-decoration:none; color:white" href="/#about">About</a></v-flex> -->
                      <!-- <v-hover v-slot="{ hover }"> -->
                      <v-flex style="text-align: justify; cursor: pointer" text-center align-self-center>
                        <router-link to="/" style="text-decoration: none">
                          <v-hover v-slot="{ hover }"><span class="mr-3"
                              :style="hover ? 'color:#1B6DC1' : 'color:black'">
                              Home</span></v-hover>
                        </router-link>
                      </v-flex>
                      <v-flex style="text-align: justify; cursor: pointer" text-center align-self-center>
                        <div class="dropdown-container">
                          <v-menu offset-y v-model="openState['Services']">
                            <template v-slot:activator="{ on, attrs }">
                              <v-hover v-slot="{ hover }">
                                <v-flex
                                  style="display: inline-flex; align-items: center; text-align: justify; cursor: pointer;"
                                  text-center align-self-center v-bind="attrs" v-on="on">
                                  <span class="mr-3" :style="hover ? 'color:#1B6DC1' : 'color:black'">
                                    Services
                                    <v-icon
                                      :style="{ color: hover ? '#1B6DC1' : 'black', transform: openState['Services'] ? 'rotate(180deg)' : 'rotate(0deg)' }">
                                      mdi-chevron-down
                                    </v-icon>
                                  </span>
                                </v-flex>
                              </v-hover>
                            </template>
                            <v-list style="font-family:OutfitMedium;">
                              <v-list-item v-for="(service, index) in services" :key="index">
                                <template v-if="service.route">
                                  <router-link :to="service.route" tag="div" style="width: 100%; cursor: pointer;"
                                    @click="selectService(service, 'service')"
                                    :style="{ color: selectedService.name === service.name ? '#1B6DC1' : 'black' }">
                                    {{ service.name }}
                                  </router-link>
                                </template>
                                <template v-else>
                                  <template v-if="service.subservices && service.subservices.length > 0">
                                    <v-menu offset-x nudge-right="20" v-model="openState[service.name]">
                                      <template v-slot:activator="{ on, attrs }">
                                        <v-list-item-content v-on="on" v-bind="attrs">
                                          <v-list-item-title @click="selectService(service, 'service')"
                                            :style="{ color: selectedService.name === service.name ? '#1B6DC1' : 'black' }">
                                            {{ service.name }}
                                            <v-icon
                                              :style="{ transform: openState[service.name] ? 'rotate(-90deg)' : 'rotate(0deg)' }">
                                              mdi-chevron-down
                                            </v-icon>
                                          </v-list-item-title>
                                        </v-list-item-content>
                                      </template>
                                      <v-list style="font-family:OutfitMedium;">
                                        <v-list-item v-for="(subService, subIndex) in service.subservices"
                                          :key="subIndex">
                                          <template
                                            v-if="subService.subsubservices && subService.subsubservices.length > 0">
                                            <v-menu offset-x nudge-right="17" v-model="openState[subService.name]">
                                              <template v-slot:activator="{ on, attrs }">
                                                <v-list-item-content v-on="on" v-bind="attrs">
                                                  <v-list-item-title
                                                    @click="selectSubService(service, subService, 'subService')"
                                                    :style="{ color: selectedSubService.name === subService.name ? '#1B6DC1' : 'black' }">
                                                    {{ subService.name }}
                                                    <v-icon
                                                      :style="{ transform: openState[subService.name] ? 'rotate(-90deg)' : 'rotate(0deg)' }">
                                                      mdi-chevron-down
                                                    </v-icon>
                                                  </v-list-item-title>
                                                </v-list-item-content>
                                              </template>
                                              <v-list style="font-family:OutfitMedium;">
                                                <v-list-item
                                                  v-for="(subsubService, subsubIndex) in subService.subsubservices"
                                                  :key="subsubIndex">
                                                  <template
                                                    v-if="subsubService.subsubsubservices && subsubService.subsubsubservices.length > 0">
                                                    <v-menu offset-x nudge-right="15"
                                                      v-model="openState[subsubService.name]">
                                                      <template v-slot:activator="{ on, attrs }">
                                                        <v-list-item-content v-on="on" v-bind="attrs">
                                                          <v-list-item-title
                                                            @click="selectSubService(subService, subsubService, 'subsubService')"
                                                            :style="{ color: selectedSubService.name === subsubService.name ? '#1B6DC1' : 'black' }">
                                                            {{ subsubService.name }}
                                                            <v-icon
                                                              :style="{ transform: openState[subsubService.name] ? 'rotate(-90deg)' : 'rotate(0deg)' }">
                                                              mdi-chevron-down
                                                            </v-icon>
                                                          </v-list-item-title>
                                                        </v-list-item-content>
                                                      </template>
                                                      <v-list style="font-family:OutfitMedium; cursor: pointer">
                                                        <v-list-item
                                                          v-for="(subsubsubService, subsubsubIndex) in subsubService.subsubsubservices"
                                                          :key="subsubsubIndex">
                                                          <template v-if="subsubsubService.route">
                                                            <router-link :to="subsubsubService.route" tag="div"
                                                              style="width: 100%"
                                                              @click="selectSubService(subsubService, subsubsubService, 'subsubsubService')"
                                                              :style="{ color: selectedSubService.name === subsubsubService.name ? '#1B6DC1' : 'black' }">
                                                              {{ subsubsubService.name }}
                                                            </router-link>
                                                          </template>
                                                          <template v-else>
                                                            <v-list-item-title
                                                              @click="selectSubService(subsubService, subsubsubService, 'subsubsubService')"
                                                              :style="{ color: selectedSubService.name === subsubsubService.name ? '#1B6DC1' : 'black' }">
                                                              {{ subsubsubService.name }}
                                                            </v-list-item-title>
                                                          </template>
                                                        </v-list-item>
                                                      </v-list>
                                                    </v-menu>
                                                  </template>
                                                  <template v-else>
                                                    <template v-if="subsubService.route">
                                                      <router-link :to="subsubService.route" tag="div"
                                                        style="width: 100%;"
                                                        @click="selectSubService(subService, subsubService, 'subsubService')"
                                                        :style="{ color: selectedSubService.name === subsubService.name ? '#1B6DC1' : 'black' }">
                                                        {{ subsubService.name }}
                                                      </router-link>
                                                    </template>
                                                    <template v-else>
                                                      <v-list-item-title
                                                        @click="selectSubService(subService, subsubService, 'subsubService')"
                                                        :style="{ color: selectedSubService.name === subsubService.name ? '#1B6DC1' : 'black' }">
                                                        {{ subsubService.name }}
                                                      </v-list-item-title>
                                                    </template>
                                                  </template>
                                                </v-list-item>
                                              </v-list>
                                            </v-menu>
                                          </template>
                                          <template v-else>
                                            <v-list-item-title
                                              @click="selectSubService(service, subService, 'subService')"
                                              :style="{ color: selectedSubService.name === subService.name ? '#1B6DC1' : 'black' }">
                                              {{ subService.name }}
                                            </v-list-item-title>
                                          </template>
                                        </v-list-item>
                                      </v-list>
                                    </v-menu>
                                  </template>
                                  <template v-else>
                                    <v-list-item-content>
                                      <v-list-item-title @click="selectService(service, 'service')"
                                        :style="{ color: selectedService.name === service.name ? '#1B6DC1' : 'black' }">
                                        {{ service.name }}
                                      </v-list-item-title>
                                    </v-list-item-content>
                                  </template>
                                </template>
                              </v-list-item>
                            </v-list>
                          </v-menu>
                        </div>
                      </v-flex>
                      <v-flex style="text-align: justify; cursor: pointer" text-center align-self-center v-scroll-to="{
                        el: '#our-aboutus',
                        duration: 500,
                        easing: 'ease-in',
                      }">
                        <router-link to="/" style="text-decoration: none"><v-hover v-slot="{ hover }">
                            <span class="mr-3" :style="hover ? 'color:#1B6DC1' : 'color:black'">About
                              Us</span></v-hover></router-link>
                      </v-flex>

                      <v-flex style="text-align: justify; cursor: pointer" text-center align-self-center v-scroll-to="{
                        el: '#our-aboutus',
                        duration: 500,
                        easing: 'ease-in',
                      }">
                        <router-link to="/" style="text-decoration: none"><v-hover v-slot="{ hover }">
                            <span class="mr-3"
                              :style="hover ? 'color:#1B6DC1' : 'color:black'">Courses</span></v-hover></router-link>
                      </v-flex>
                      <v-flex style="text-align: justify; cursor: pointer" text-center align-self-center>
                        <router-link to="/contactUs" style="text-decoration: none">
                          <v-hover v-slot="{ hover }">
                            <span class="mr-3" :style="hover ? 'color:#1B6DC1' : 'color:#2E2E2E'">Contact Us
                            </span></v-hover></router-link>
                      </v-flex>
                      <v-flex py-6>
                        <v-divider vertical style="min-height: 50px; max-height: 50px"></v-divider>
                      </v-flex>

                      <v-flex style="text-align: justify; cursor: pointer" text-center align-self-center v-scroll-to="{
                        el: '#our-aboutus',
                        duration: 500,
                        easing: 'ease-in',
                      }">
                        <v-icon color="black" class="mb-1">mdi-account</v-icon>
                        <!-- Added user icon -->

                        <router-link to="/" style="text-decoration: none">
                          <v-hover v-slot="{ hover }">
                            <span class="ml-2" :style="hover ? 'color:#1B6DC1' : 'color:black'">Login</span>
                          </v-hover>
                        </router-link>

                        <v-btn class="ml-3" color="primary" small>
                          <router-link to="/" style="text-decoration: none">
                            <span style="color: white">Sign Up</span>
                          </router-link>
                        </v-btn>
                      </v-flex>
                    </v-layout>
                  </v-layout>
                  <!-- </v-card> -->
                </v-flex>
              </v-layout>
              <!-- </v-img> -->
            </v-flex>
          </v-layout>
          <!-- </header> -->
        </v-app-bar>
        <v-divider style="border-color: black;"></v-divider>
      </v-flex>
    </v-layout>


    <!-- MOBILE SCREEN -->

    <v-layout wrap class="hidden-md-and-up">
      <v-flex xs12>
        <v-app-bar color="white" height="70px" class="px-0" dense style="z-index:1;">
          <v-layout class="hidden-md-and-up">
            <v-flex xs12>
              <!-- <v-img src="../../assets/Images/hd.jpg"> -->
              <v-layout wrap>
                <v-flex xs11 align-self-start>
                  <router-link to="/" style="text-decoration: none">
                    <v-layout wrap justify-start>
                      <v-flex xs3 sm2 pt-1>
                        <!-- <v-img contain text src="../assets/Images/kumarakom.png" height="40px"></v-img> -->

                        <v-img contain style="cursor: pointer" text src="./../../assets/kumarakom.png"
                          height="60px"></v-img>
                      </v-flex>
                    </v-layout>
                  </router-link>
                </v-flex>
                <v-flex xs1 pt-5>
                  <v-app-bar-nav-icon @click="drawer = !drawer">
                    <v-icon color="White">mdi-menu</v-icon>
                  </v-app-bar-nav-icon>
                </v-flex>
              </v-layout>
              <!-- </v-img> -->
            </v-flex>
          </v-layout>
        </v-app-bar>
      </v-flex>
      <v-navigation-drawer :width="$vuetify.breakpoint.name == 'sm'
        ? '360px'
        : '340px'" right v-model="drawer" app fixed temporary>
        <v-layout wrap justify-center>
          <v-flex>
            <div class="sidebar">
              <ul>
                <li v-for="item in items" :key="item.name">
                  <div @click="toggle(item)">
                    <i :class="item.icon"></i>
                    <router-link style="text-decoration:none;color:black" v-if="item.route" :to="item.route"
                      @click.native="drawer = false">{{ item.name }}</router-link>
                    <span v-else>{{ item.name }}</span>
                    <v-icon v-if="item.children && item.children.length > 0">
                      {{ item.expanded ? 'mdi-menu-down' : 'mdi-menu-right' }}
                    </v-icon>
                  </div>
                  <ul v-if="item.children && item.expanded">
                    <li v-for="child in item.children" :key="child.name">
                      <div @click="toggle(child)">
                        <i :class="child.icon"></i>
                        <router-link style="text-decoration:none;color:black" v-if="child.route" :to="child.route"
                          @click.native="drawer = false">{{ child.name }}</router-link>
                        <span v-else>{{ child.name }}</span>
                        <v-icon v-if="child.children && child.children.length > 0">
                          {{ child.expanded ? 'mdi-menu-down' : 'mdi-menu-right' }}
                        </v-icon>
                      </div>
                      <ul v-if="child.children && child.expanded">
                        <li v-for="grandChild in child.children" :key="grandChild.name">
                          <div @click="toggle(grandChild)">
                            <i :class="grandChild.icon"></i>
                            <router-link style="text-decoration:none;color:black" v-if="grandChild.route"
                              :to="grandChild.route" @click.native="drawer = false">{{ grandChild.name }}</router-link>
                            <span v-else>{{ grandChild.name }}</span>
                            <v-icon v-if="grandChild.children && grandChild.children.length > 0">
                              {{ grandChild.expanded ? 'mdi-menu-down' : 'mdi-menu-right' }}
                            </v-icon>
                          </div>
                          <ul v-if="grandChild.children && grandChild.expanded">
                            <li v-for="greatGrandChild in grandChild.children" :key="greatGrandChild.name">
                              <div @click="toggle(greatGrandChild)">
                                <i :class="greatGrandChild.icon"></i>
                                <router-link style="text-decoration:none;color:black" v-if="greatGrandChild.route"
                                  :to="greatGrandChild.route" @click.native="drawer = false">{{ greatGrandChild.name
                                  }}</router-link>
                                <span v-else>{{ greatGrandChild.name }}</span>
                                <v-icon v-if="greatGrandChild.children && greatGrandChild.children.length > 0">
                                  {{ greatGrandChild.expanded ? 'mdi-menu-down' : 'mdi-menu-right' }}
                                </v-icon>
                              </div>
                              <ul v-if="greatGrandChild.children && greatGrandChild.expanded">
                                <li v-for="greatGreatGrandChild in greatGrandChild.children"
                                  :key="greatGreatGrandChild.name">
                                  <div>
                                    <router-link style="text-decoration:none;color:black"
                                      v-if="greatGreatGrandChild.route" :to="greatGreatGrandChild.route"
                                      @click.native="drawer = false">{{
                                      greatGreatGrandChild.name }}</router-link>
                                    <span v-else>{{ greatGreatGrandChild.name }}</span>
                                  </div>
                                </li>
                              </ul>
                            </li>
                          </ul>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
          </v-flex>
        </v-layout>
      </v-navigation-drawer>



    </v-layout>
    <!-- </v-app-bar> -->
  </div>
</template>
<script>
export default {
  name: 'Sidebar',
  data() {
    return {
      openState: {},
      selectedService: {}, // Selected main service
      selectedSubService: {}, // Selected subservice or deeper
      drawer: false,
      selectedType: null,
      mainMenu: false,

      services: [
        {
          name: "Rubicon Career Development",
          subservices: [
            {
              name: "Learning",
              subsubservices: [
                {
                  name: "Languages",
                  subsubsubservices: [
                    { name: "Rubycon Carmel Course", route: '/carmelcourse' },
                  ],
                },
                {
                  name: "Computer Courses",
                  subsubsubservices: [
                    { name: "Rubycon Zen Course", route: '/zenintro' },
                  ],
                },
                { name: "Medical Courses" },
                { name: "Accounting Software" },
              ],
            },
            {
              name: "Training and Certification",
              subsubservices: [
                { name: "Health & Safety ", route: '/onlinecourse' },
              ],
            },
          ],
        },
        {
          name: "Real Estate",
          route: "/realestate",
        },
        {
          name: "Business Strategy",
        },
        {
          name: "Health Counselling Services",
        },
        {
          name: "Global-Steel Procurement",
        },
        {
          name: "Website & IT",
        },
      ],

      items: [
        {
          name: 'Home',
          icon: 'fas fa-home',
          expanded: false,
          route: '/',
          children: []
        },
        {
          name: 'Services',
          icon: 'fas fa-concierge-bell',
          expanded: false,
          children: [
            {
              name: 'Rubicon Career Development',
              icon: 'fas fa-briefcase',
              expanded: false,
              children: [
                {
                  name: 'Learning',
                  icon: 'fas fa-book',
                  expanded: false,
                  children: [
                    {
                      name: 'Languages',
                      icon: 'fas fa-language',
                      expanded: false,
                      children: [
                        {
                          name: 'Rubycon Carmel Course',
                          icon: 'fas fa-chalkboard-teacher',
                          expanded: false,
                          route: '/carmelcourse',
                          children: []
                        }
                      ]
                    },
                    {
                      name: 'Computer Courses',
                      icon: 'fas fa-desktop',
                      expanded: false,
                      children: [
                        {
                          name: 'Rubycon Zen Course',
                          icon: 'fas fa-laptop-code',
                          route: '/zenintro',
                          expanded: false,
                          children: []
                        }
                      ]
                    },
                    {
                      name: 'Medical Courses',
                      icon: 'fas fa-stethoscope',
                      expanded: false,
                      children: []
                    },
                    {
                      name: 'Accounting Software',
                      icon: 'fas fa-calculator',
                      expanded: false,
                      children: []
                    }
                  ]
                },
                {
                  name: 'Training',
                  icon: 'fas fa-chalkboard',
                  expanded: false,
                  children: [
                    {
                      name: 'Health & Safety',
                      icon: 'fas fa-heartbeat',
                      expanded: false,
                      route: '/onlinecourse',
                      children: []
                    }
                  ]
                }
              ]
            },
            {
              name: 'Real Estate',
              icon: 'fas fa-building',
              expanded: false,
              children: []
            },
            {
              name: 'Business Strategy',
              icon: 'fas fa-chart-line',
              expanded: false,
              children: []
            },
            {
              name: 'Health Counselling Services',
              icon: 'fas fa-user-md',
              expanded: false,
              children: []
            },
            {
              name: 'Global-Steel Procurement',
              icon: 'fas fa-industry',
              expanded: false,
              children: []
            }
          ]
        },
        {
          name: 'About Us',
          icon: 'fas fa-info-circle',
          expanded: false,
          route: '/',
          children: []
        },
        {
          name: 'Courses',
          icon: 'fas fa-graduation-cap',
          expanded: false,
          route: '/',
          children: []
        },
        {
          name: 'Contact Us',
          icon: 'fas fa-envelope',
          expanded: false,
          route: '/contactUs',
          children: []
        },
        {
          name: 'Login',
          icon: 'fas fa-sign-in-alt',
          expanded: false,
          route: '/',
          children: []
        },
        {
          name: 'Sign Up',
          icon: 'fas fa-user-plus',
          expanded: false,
          route: '/',
          children: []
        }
      ]


      // items: [
      //   {
      //     action: "mdi-home",

      //     title: "Home",
      //     route: "/"
      //   },
      //   {
      //     action: "mdi-account-circle",
      //     active: true,
      //     items: [
      //       {
      //         title: "Rubicon Career Development", route: "",
      //         items: [
      //           {
      //             title: "Learning",
      //             items: [
      //               {
      //                 title: "Languages", route: "/",
      //                 items: [
      //                   { title: "Rubycon Carmel Course", route: "/carmelcourse" },

      //                 ],
      //               },

      //               {
      //                 title: "Computer Courses", route: "/",
      //                 items: [
      //                   { title: "Rubycon Zen Course", route: "/zenintro" },
      //                 ],

      //               },
      //               { title: "Medical Courses", route: "/" },
      //               { title: "Acounting Software", route: "/" },
      //             ],
      //           },
      //           {
      //             title: "Training and Certification",
      //             items: [
      //               {
      //                 title: "Health & Safety", route: "/onlinecourse"

      //               },


      //             ],
      //           },
      //         ],
      //       },
      //       { title: "Real Estate", route: "/realestate" },
      //       { title: "Business Strategy", route: "" },
      //       { title: "Health Counselling Services", route: "" },
      //       { title: "Gloal-Steel Procurement", route: "" },

      //     ],
      //     title: "Services",
      //     route: "/",
      //   },
      //   {
      //     action: "mdi-account-group",
      //     route: "/",
      //     title: "About Us",
      //   },
      //   {
      //     action: "mdi-book",
      //     route: "/",
      //     title: "Courses",
      //   },
      //   {
      //     action: "mdi-phone",
      //     route: "/",
      //     title: "Contact Us",
      //   },

      //   {
      //     action: "mdi-account",
      //     route: "/",
      //     title: "Login",
      //   },
      //   {
      //     action: "mdi-account",
      //     route: "/",
      //     title: "Sign Up",
      //   },
      // ],
    };
  },
  methods: {

    toggle(item) {
      item.expanded = !item.expanded;
    },

    selectService(service, type) {
      if (type === 'service') {
        this.selectedService = service;
      }
      else if (type === 'subService' || type === 'subsubService' || type === 'subsubsubService') {
        this.selectedSubService = service;
      }
    },
    selectSubService(parentService, service, type) {
      this.selectService(parentService, 'service'); // Ensure main service stays selected
      this.selectService(service, type);
    },
    openMenu(menuName) {
      this.$set(this.openState, menuName, true);
    },
    closeMenu(menuName) {
      this.$set(this.openState, menuName, false);
    },

  },
};
</script>

<style>
.router-link-active,
.router-link-exact-active,
.router-link {
  color: black !important;
  text-decoration: none !important;
  /* Optional: to remove underline */
}

.v-list-item-title .router-link-active,
.v-list-item-title .router-link-exact-active,
.v-list-item-title .router-link {
  color: black !important;
  text-decoration: none !important;
  /* Optional: to remove underline */
}

.v-list-group__header__append-icon {
  display: none;
}

.selected-item {
  color: #0b5ce7;
}

.dropdown-container {
  position: relative;
}

.router-link {
  text-decoration: none;
}

.navbar {
  overflow: hidden;
  background-color: #333;
  position: fixed;
  top: 0;
  width: 100%;
}

.fixItem {
  position: -webkit-sticky;
  position: sticky;
  top: 0px;
  z-index: 1;
  /* overflow: hidden;
        position: relative;
        height: 100%; */
}

.fnt1 {
  font-size: 13px;
  font-weight: bold;
}

.app-bar {
  position: relative;
  /* Ensure it has a position context */
  overflow: visible;
  /* Ensure overflow is visible */
  z-index: 1000;
  top: 0;
  left: 0;
  width: 100%;
  height: 64px;
  /* adjust to desired height */
  background-color: #fff;
  /* adjust to desired background color */
  border-bottom: 1px solid #ccc;
  /* adjust to desired border style */

  /* adjust to desired z-index */
}

.app-content {
  margin-top: 64px;
  /* adjust to match app bar height */
}

.gradientorange {
  /* background-image:inear-gradient(255.36deg, #7BC142 48.09%, #E95D49 127.63%) ; */
  background-image: linear-gradient(255.36deg, #7bc142 48.09%, #e95d49 127.63%);
}

.app-header {
  position: relative;
  overflow: visible;
  z-index: 1000;
  top: 0;
  left: 0;
  right: 0;
  height: 60px;
  background-color: #fff;
  border-bottom: 1px solid #ccc;
}

.container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: auto;
}

.headerfont {
  font-family: OutfitMedium;
}

.sidebar {
  font-family: outfitMedium;
  background-color: #f8f9fa;
  padding: 10px;
}

.sidebar ul {
  list-style-type: none;
  padding: 0;
}

.sidebar ul ul {
  margin-left: 20px;
}

.sidebar div {
  cursor: pointer;
  padding: 15px;
}

.sidebar div:hover {
  background-color: #e9ecef;
}

.sidebar ul li i {
  margin-right: 8px;
  /* Adjust the value for icon spacing */
}
</style>