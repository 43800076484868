<template>
  <v-app>
    <v-snackbar
        v-model="showSnackBar"
        color="#FF1313"
        left
        :timeout="timeout"
      >
        <v-layout wrap justify-center>
          <v-flex text-left class="align-self-center">{{ msg }}</v-flex>
          <v-flex text-right>
            <v-btn small :ripple="false" text @click="showSnackBar = false">
              <v-icon style="color: white">mdi-close</v-icon>
            </v-btn>
          </v-flex>
        </v-layout>
      </v-snackbar>

      <!-- <div id="app">
        <appHeader />
      </div> -->

    <AppHeader style="z-index:500;" v-if="$route.name=='mainHome' || $route.name=='realestate'" />
    <router-view
      style="min-height: 90vh"
      :key="$route.fullPath"
    ></router-view>
    <AppFooter />
  </v-app>
</template>

<script>
import AppHeader from "./components/Common/appHeader";
// import AppHeader2 from "./components/Common/appHeader2";
import AppFooter from "./components/Common/appFooter";
// import axios from "axios";

// import appHeader from './components/Common/appHeader.vue';

import "./assets/styles/fonts.css";
import "./assets/styles/styles.css";

export default {
  name: "App",
  components: {
    AppHeader,
    AppFooter,
    // appHeader,
  },
  data() {
    return {
      sideNav: false,
      appLoading: false,
      ServerError: false,
      showSnackBar: false,
      msg: null,
      timeout: 5000,
    };
  },
  beforeMount() {
  },
  methods: {
  },
};
</script>
<style>
a {
  text-decoration: none;
  /* color:#000000 !important; */
}
.itemHeading {
  font-family: sourceSansProBlack;
  font-size: 30px;
  color: #000000;
  letter-spacing: 1px;
}
.itemHeadingSub {
  font-family: sourceSansProBlack;
  font-size: 25px;
  color: #6AB43E;
  letter-spacing: 1px;
}
.itemHeading2 {
  font-family: sourceSansProBlack;
  font-size: 24px;
  color: #000000;
}
.itemSubHeading {
  font-family: sourceSansProBold;
  font-size: 26px;
  color: #000000;
}
.itemCaption {
  font-family: sourceSansProSemibold;
  font-size: 18px;
  color: #1a1919;
}
.itemCaption1{
  font-family: sourceSansProSemibold;
  font-size: 18px;
  color: #ffffff;
}
.itemText {
  font-family: sourceSansProLight;
  font-size: 18px;
  color: #414141;
}
.textField {
  font-family: sourceSansProRegular;
  font-size: 12px;
  letter-spacing: 0.75px;
  color: #d9d9d9;
}
.textField2 {
  text-align: left;
  font-family: sourceSansProRegular;
  letter-spacing: 0px;
  color: #9e9e9e;
}
.tableHeading {
  font-family: sourceSansProRegular;
  font-size: 18px;
  letter-spacing: 0.14px;
  color: #9b9090;
}
.tableValue {
  font-family: sourceSansProSemibold;
  font-size: 18px;
  letter-spacing: 0.14px;
  color: #000000;
}
.tableValue1 {
  font-family: sourceSansProSemibold;
  font-size: 17px;
  letter-spacing: 0.14px; 
  color: #000000;
}
.slotText1 {
    font-size: 14px;
    font-family: sourceSansProRegular;
    color: #9e9e9e;
  }
.itemTags { 
  font-family: sourceSansProRegular;
  font-size: 18px;
  letter-spacing: 0.14px;
  color: #000000;
}
.cardSubHeading {
  font-family: sourceSansProSemibold;
  font-size: 18px;
  color: #6AB43E;
  letter-spacing: 1.5px;
}
.buttonText {
  font-family: montserratLight;
  font-size: 14px;
  color: #ffffff;
  letter-spacing: 1px;
  text-transform: none;
}
.pageForm {
  font-family: sourceSansProRegular;
  font-size: 14px;
  color: #000000;
}
.bookingHeading {
  font-family: montserratBold;
  font-size: 18px;
  color: #000000;
}
</style>