import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './router';
//import axios from 'axios'

import $ from 'jquery'
window.$ = $


import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.js';
import 'bootstrap/dist/js/bootstrap.bundle.js';

import VueElementLoading from "vue-element-loading";
import ImageLoader from "./components/Common/imageLoader";
import ServerError from "./components/Common/500";
Vue.component("VueElementLoading", VueElementLoading);
Vue.component("ImageLoader", ImageLoader);
Vue.component("ServerError", ServerError);
import OwlCarousel from 'vue-owl-carousel'
Vue.component('OwlCarousel', OwlCarousel)
import AOS from 'aos'
import 'aos/dist/aos.css'


// Vue.prototype.mediaUURL = "http://192.168.49.195:5001/file/get/"
// axios.defaults.baseURL = "http://192.168.49.195:5001";
// Vue.prototype.mediaURL = "http://192.168.49.195:5001/wp/";
// Vue.prototype.ipURL = "http://192.168.49.195:5001"

Vue.config.productionTip = false

new Vue({
    vuetify,
    router,
    render: h => h(App),
    mounted() {
        AOS.init()
    },
}).$mount('#app')